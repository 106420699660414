interface UseCountDownOptions {
  format?: Ref<string> | string
}

export function useCountDown(
  deadline: number | string | Ref<number | string> | (() => number | string),
  { format = 'DD:HH:mm' }: UseCountDownOptions = {},
) {
  const { parseDate, duration } = useDate()

  const interval = ref()
  const countDown = ref('')

  const startDate = computed(() => {
    const date = parseDate(toValue(deadline))
    if (!date.isValid()) {
      console.error(
        `Invalid props value for new Date - ${deadline}, correct the 'deadline' prop`,
      )
      return 0
    }

    return date.valueOf()
  })

  function startCountDown() {
    interval.value = setInterval(() => {
      const currentDate = parseDate().valueOf()

      const diffDate = startDate.value ? startDate.value - currentDate : 0
      const durationValue = duration(diffDate)

      if (diffDate > 0) {
        countDown.value = durationValue.format(toValue(format))
      } else {
        clearInterval(interval.value)
        countDown.value = ''
      }
    }, 1000)
  }

  onUnmounted(() => clearInterval(interval.value))

  return {
    countDown,
    startCountDown,
  }
}

export interface UseCountdownV2Options {
  isPaused: Ref<boolean>
}

export interface UseCountdownV2Return {
  countdown: Ref<string>
  countdownInMs: Ref<number>
  countdownInUnits: Ref<{
    days: string
    hours: string
    minutes: string
    seconds: string
  }>
  isExpired: Ref<boolean>
}

export function useCountdownV2(
  deadline: Ref<number | string>,
  options?: UseCountdownV2Options,
): UseCountdownV2Return {
  const { parseDate, duration: parseDuration } = useDate()

  const isExpired = computed(() =>
    parseDate(deadline.value).isBefore(parseDate()),
  )

  const currentTimestamp = useTimestamp({ interval: 1000 })
  const initialTimestamp = ref(currentTimestamp.value)

  watch(
    () => toValue(options?.isPaused),
    () => {
      initialTimestamp.value = currentTimestamp.value
    },
  )

  const countdownAsDuration = computed(() => {
    const timestamp = toValue(options?.isPaused)
      ? initialTimestamp.value
      : currentTimestamp.value
    return parseDuration(
      parseDate(toValue(deadline)).diff(parseDate(timestamp)),
    )
  })

  const countdownInMs = computed(() =>
    countdownAsDuration.value.asMilliseconds(),
  )

  const countdown = computed(() => {
    const duration = countdownAsDuration.value

    if (duration.asDays() > 1) return duration.format('DD:HH:mm:ss')
    if (duration.asHours() > 1) return duration.format('HH:mm:ss')
    return duration.format('mm:ss')
  })

  const countdownInUnits = computed(() => {
    const duration = countdownAsDuration.value

    return {
      days: duration.asDays().toString(),
      hours: duration.hours().toString().padStart(2, '0'),
      minutes: duration.minutes().toString().padStart(2, '0'),
      seconds: duration.seconds().toString().padStart(2, '0'),
    }
  })

  return {
    countdown,
    countdownInMs,
    countdownInUnits,
    isExpired,
  }
}
